.ac .highlight {
  background: rgba(105, 213, 177, 0.3);
}

.ac .ac-menu {
  position: absolute;
  z-index: 1;
  left: var(--filter-input-offset);
  width: calc(100% - var(--filter-input-offset));
  max-height: 150px;
  overflow: auto;
  background: #fff;
  border: 1px solid rgba(155, 155, 155, 0.5);
  border-top: 0;
}

.ac .clear-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

@media all and (max-width: 576px) {
  .ac .ac-menu {
    width: calc(100% - var(--filter-input-offset) * 2);
  }
  .ac .clear-btn {
    right: 20px;
  }
}

.ac .ac-item {
  cursor: pointer;
  padding: 5px;
}
