.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(100,100,100,0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.modal-container.active {
  opacity: 1;
  z-index: 999;
  pointer-events: auto;
}

.modal-container .modal-box {
  background: #fff;
  max-width: 780px;
  width: 95%;
  min-height: 100px;
  border-radius: 5px;
  margin: 300px auto;
  opacity: 0;
  position: relative;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

@media all and (max-width: 800px) {
  .modal-container .modal-box {
    background: #fff;
    width: 99vw;
    min-height: 100px;
    border-radius: 5px;
    margin: 10px auto;
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
}

.modal-container.active .modal-box {
  margin: 100px auto;
  opacity: 1;
}

.modal-container .modal-box .close {
  border: none;
  background: #ddd;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: #000;
  font-size: 20px;
  cursor: pointer;
}

.modal-container .modal-box .modal-head {
  border-bottom: 1px solid #e8e7e7;
}
.modal-container .modal-box .modal-head h2 {
  color: #00bc89;
  letter-spacing: .05em;
  text-transform: uppercase;
  padding: 0 25px 10px;
  margin: 0;
}
.modal-container .modal-box .modal-content {
  padding: 15px 25px 0 25px;
  color: #000022;
  overflow: hidden;
}
.scroll-helper {
  height: auto;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin: 0 auto 10px;
}

@media all and (max-width: 800px) {
  .modal-container .modal-box {
    padding: 5px;
    margin: 0;
  }

  .modal-container.active .modal-box {
    margin: 5px auto;
  }

  .modal-container .modal-box .modal-head h2 {
    padding: 15px;
  }

  .modal-container .modal-box .modal-content {
    padding-inline: 0;
  }
}