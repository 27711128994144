.social-link {
  margin: 0 10px;
}

.social-link svg path {
  transition: fill 255ms ease;
}

.social-link:hover svg path {
  fill: #65d6af;
}

.social-wrapper {
  align-self: center;
  margin-top: 30px;
}

@media all and (max-width: 800px) {
  .social-wrapper {
    margin-top: 10px;
  }

  .social-link {
    margin: 0 5px;
  }
}
